<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <div class="d-flex mm_center_items">
            <p class="large--title m-0"> {{ $t("message.sending_cargo") }}</p>
            <select-to-filial
                :size="'large'"
                class="w-100 ml-2"
                :class="mode ? 'filter__day' : 'filter__night'"
                :placeholder="$t('message.to_filial')"
                v-model="filterForm.to_filial_id"
            >
            </select-to-filial>
            <select-client
                :size="'large'"
                class="w-100 ml-2"
                :class="mode ? 'filter__day' : 'filter__night'"
                :placeholder="$t('message.reciever')"
                v-model="filterForm.client_id"
                >
            </select-client>
          </div>
          
          <div>
            <div>
              <el-button type="success" size="medium" @click="addToPartyList()" plain>
                  {{ $t("message.add_to_party") }}
              </el-button>
              <el-button type="warning" size="medium" @click="close()" plain>
                {{ $t("message.close") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div v-loading="loadingData" class="timeline-items__right rounded-sm w-100 p-4">
          <el-table 
              ref="sendingProductsList"
              header-row-class-name="header__class"
              row-class-name="row__class"
              :data="list" 
              @selection-change="handleSelectionChange"
              stripe border
              style="width: 100%">
              <el-table-column :selectable="canSelectThisRow"
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column
                  type="index"
                  width="50">
              </el-table-column>
              <el-table-column :label="$t('message.deal')" width="65">
                  <template slot-scope="scope"> 
                    {{ scope.row.deal_id }} 
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.party')" width="72">
                  <template slot-scope="scope"> 
                    {{ scope.row.delivery_party_id }} 
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.reciever')">
                  <template slot-scope="scope"> 
                    <span v-html="scope.row.reciever"></span>
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.partner')">
                  <template slot-scope="scope"> 
                    <span v-html="scope.row.partner"></span>
                  </template>
              </el-table-column>
              <!-- <el-table-column :label="$t('message.to_filial')">
                  <template slot-scope="scope"> 
                    {{scope.row.to_filial}}
                  </template>
              </el-table-column> -->
              <el-table-column :label="$t('message.address')">
                  <template slot-scope="scope"> 
                    {{scope.row.address}}
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope"> 
                      {{ scope.row.name }}
                      <span v-if="scope.row.type == 'package' && ((scope.row.reciever_id && scope.row.reciever_id.length > 1) || scope.row.address_count > 1)">  
                        <el-tooltip class="item" effect="dark" :content="$t('message.package_includes_multi_direction_deals')" placement="top">
                          <i class="el-icon-warning" style="font-size: 20px; color: brown;"></i>
                        </el-tooltip>
                      </span>
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.barcode')">
                  <template slot-scope="scope"> 
                      <span>{{ scope.row.barcode }} </span>    
                  </template>
              </el-table-column>
              <el-table-column :label="$t('message.quantity_y')" width="65">
                  <template slot-scope="scope"> 
                      <span>{{ scope.row.remainder }} </span>    
                  </template>
              </el-table-column>
              <!-- <el-table-column :label="$t('message.current_quantity')" width="90">
                <template slot-scope="scope">
                  <el-input
                      :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                      :min="0"
                      type="number"
                      size="mini"
                      @input="checkValue(scope.row)"
                      v-model="scope.row.incoming_quantity"
                  ></el-input>
                </template>
              </el-table-column> -->

              <el-table-column :label="columns.weight.title" width="100">
                  <template slot-scope="scope">
                    {{ scope.row.weight | formatNumber(1) }} kg <br> 
                    {{ (scope.row.weight * $kg_to_pound) | formatNumber(1) }} lbs
                  </template>
              </el-table-column>

              <el-table-column :label="$t('message.dimension_sm')" width="110">
                  <template slot-scope="scope">
                      {{ (scope.row.width || '10') + 'x' + (scope.row.height || '10') + 'x' + (scope.row.length || '10') }}
                  </template>
              </el-table-column>

              <el-table-column :label="$t('message.comment')">
                  <template slot-scope="scope"> 
                      <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                  </template>
              </el-table-column>
              <el-table-column width="60">
                  <template slot="header" slot-scope="scope">
                    <i class="el-icon-setting"></i>
                  </template>
                  <template slot-scope="scope"> 
                    <el-button v-if="canAttachAddress(scope.row)" style="padding: 10px !important;" @click="addDealAdress(scope.row)" type="primary" icon="el-icon-add-location"></el-button>
                  </template>
              </el-table-column>
          </el-table>
        </div>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <el-drawer
        :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
        :with-header="false"
        :visible.sync="drawerClientAddress"
        ref="drawerClientAddress"
        size="70%"
        class="bg-se"
        :append-to-body="true"
        @opened="drawerOpened('drawerClientAddressChild')"
        @closed="drawerClosed('drawerClientAddressChild')"
    >
        <div>
            <client-address
              :client_id="selectedAddress.client_id"
              :type="'deal'"
              v-model="selectedAddress.deal_address"
              @input="updateAddressId"
              ref="drawerClientAddressChild"
              drawer="drawerClientAddress"
            >
            </client-address>
        </div>
    </el-drawer>
    <el-drawer
      :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
      :with-header="false"
      :visible.sync="drawerRecieverDeals"
      ref="drawerRecieverDeals"
      size="70%"
      class="bg-se"
      :append-to-body="true"
      @opened="drawerOpened('drawerRecieverDealsChild')"
      @closed="drawerClosed('drawerRecieverDealsChild')"
    >
      <div>
          <reciever-deals
            :reciever_id="selectedAddress.client_id"
            :deal_id="selectedAddress.deal_id"
            :selectedAddress="selectedAddress"
            v-model="selectedAddress.deal_ids"
            @input="updateReciverDealsAddress"
            ref="drawerRecieverDealsChild"
            drawer="drawerRecieverDeals"
          >
          </reciever-deals>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import clientAddress from "@/views/clients/components/clientAddress/clientAddress";
import recieverDeals from "./reciever_deals";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";

export default {
  
  mixins: [form, drawer],
  props: {
    party_id: {
        default: null
    },
  },
  components: {
    clientAddress,
    recieverDeals,
    selectToFilial,
    selectClient,
  },
  data() {
    return {
      filterForm: {
        to_filial_id: null,
        client_id: null,
      },
      reloadList: false,
      loadingData: false,
      filialProductsList: [],
      selectedProducts: [],
      drawerClientAddress: false,
      drawerRecieverDeals: false,
      selectedAddress: {
        deal_id: null,
        deal_ids: [],
        client_id: null,
        address_id: null,
        deal_address: {}
      }
      
    };
  },
  
  created() {},
  watch: {
    filterForm: {
      handler: async function(newVal, oldVal) {
          await this.updatePagination({ key: "page", value: 1 });
          this.fetchData();
      },
      deep: true,
      immediate: true
    },
    'pagination.page': {
        handler: async function(newVal, oldVal) {
            if (newVal != oldVal && _.isFunction(this.fetchData)) {
                this.fetchData();
            }
        },
        deep: true,
        immediate: true,
    },
    'pagination.per_page': {
        handler: async function(newVal, oldVal) {
            if (newVal != oldVal && _.isFunction(this.fetchData)) {
                this.fetchData();
            }
        },
        deep: true,
        immediate: true,
    },
  },

  computed: {
      ...mapGetters({
        columns: "dealProducts/columns",
        pagination: "cdek/filial_product_pagination",
        list: "cdek/filial_product_list",
        mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
        updateList: "cdek/incomingFilialProducts",
        updateDealsAddress: "deals/updateDealsAddress",
        updatePagination: "cdek/updateFilialProductPagination",
        setAddressToDeals: "cdek/setAddressToDeals",
        storeProductsInDeliveryParty: "deliveryParties/storeProductsInDeliveryParty",
      }),
      afterOpen(){
        this.fetchData();
      },
      updateAddressId(address){
        this.selectedAddress.address_id = address.id;
        this.drawerRecieverDeals = true;
        this.drawerClientAddress = false;
      },
      async addDealAdress(row){
        await this.setClientData(row);
        this.drawerClientAddress = true;
      },
      listChanged(){
        this.reloadList = true;
      },
      setClientData(row){
        let deal = Number.isInteger(row.deal_id) ? row.deal_id : row.deal_id.split(", ");
        if(Array.isArray(deal)){
          this.selectedAddress.deal_id = deal[0];
        }else{
          this.selectedAddress.deal_id = deal;
        }
        if(row.reciever_id && Array.isArray(row.reciever_id) && row.reciever_id.length == 1){
          this.selectedAddress.client_id = row.reciever_id[0]
        }else if(row.reciever_id && (typeof row.reciever_id == 'string' || Number.isInteger(row.reciever_id))){
          this.selectedAddress.client_id = row.reciever_id
        }
      },
      async updateReciverDealsAddress(){
          this.loadingData = true;
          await this.updateDealsAddress(this.selectedAddress);
          let deals = this.selectedAddress.deal_ids.toString();
          let address = this.selectedAddress.deal_address.text;
          await this.setAddressToDeals({deals: deals, address: address});
          this.loadingData = false;
      },
      canSelectThisRow(row, index){
        return (!row.delivery_party_id && row.address && row.reciever_id && ((Array.isArray(row.reciever_id) && row.reciever_id.length == 1) || (typeof row.reciever_id == 'string' || Number.isInteger(row.reciever_id))));
      },
      canAttachAddress(row){
        return (!row.address && row.reciever_id && ((row.type == 'package' && Array.isArray(row.reciever_id) && row.reciever_id.length == 1) || row.type == 'product'));
      },
      checkValue(){

      },
      async fetchData() {
          const query = {...this.pagination, ...this.filterForm};
          if (!this.loadingData) {
              this.loadingData = true;
              await this.updateList(query).then(res => {
                  this.loadingData = false;
              }).catch(err => {
                  this.loadingData = false
              });
              
          }
      },
      handleSelectionChange(val) {
        this.selectedProducts = val;
      },

      addToPartyList() {
        if(this.selectedProducts.length > 0){
          // ADD TO PARTY
          let sendable = this.selectedProducts.map(el => {
            return {
              id: el.id,
              type: el.type,
            }
          });
          this.storeProductsInDeliveryParty({id: this.party_id, products: sendable})
            .then(res => {
              this.fetchData();
              this.$alert(res);
              this.parent().listChanged();
            })
            .catch(err => {
              console.log(err, 'errerrerrerr');
            });
          

        }else{
          this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: this.$t('message.select_products_to_add_to_list')
          });
        }
      },
      closeDrawer(drawer) {       
          if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
              this.$refs[drawer].closeDrawer();
          }
      },
      drawerClosed(ref) {
          if (this.$refs[ref]) {
              this.$refs[ref].closed()
          }
          if (_.isFunction(this.empty)) {
              this.empty()
          }
      },
      drawerOpened(ref) {        
          if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                  this.$refs[ref].opened()
              }
          }
      },
  },
};
</script>
<style>
 .mm_center_items{
    justify-content: center;
    align-items: center;
 }
</style>
