<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.shipping_cargo") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <div style="display:flex; float: right;" class="text-right">
                <crm-create-and-column-settings
                    :permission="$options.name"
                    :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                    @c-create="createParty()"
                    :columns="columns"
                    @c-change="updateColumn"
                >
                </crm-create-and-column-settings>
            </div>
            
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.user_id.show">
                    {{ columns.user_id.title }}
                </th>

                <th v-if="columns.active.show">
                    {{ columns.active.title }}
                </th>

                <th v-if="columns.comment.show">
                    {{ columns.comment.title }}
                </th>

                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.user_id.show">
                <select-users
                    :placeholder="$t('message.responsible')"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.user_id"
                ></select-users>
              </th>

              <th v-if="columns.active.show">
                  <el-select 
                    v-model="filterForm.active" 
                    clearable
                    :placeholder="columns.active.title" 
                    :class="mode ? 'filter__day' : 'filter__night'">
                    <el-option
                        v-for="item in active_statuses"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
              </th>

              <th v-if="columns.comment.show">
                  <crm-input
                      :placeholder="columns.comment.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.comment"
                  ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="party in list" :key="party.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ party.id }}
                </td>

                <td v-if="columns.user_id.show">
                    {{ party.user ? party.user.name : '' }}
                </td>

                <td v-if="columns.active.show">
                    {{ party.active ? $t('message.active') : $t('message.inactive') }}
                </td>

                <td v-if="columns.comment.show">
                    {{ party.comment }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ party.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ party.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                    <div class="flight__edit">
                        <router-link v-if="permissions.some(per => per.slug == 'deliveryParties.update')" class="menu-drawer-submenu__item"
                            :to="{ name: 'deliveryParties.show', params: { party_id: party.id } }">
                            <i class="el-icon-edit-outline"></i>
                        </router-link>
                        <a v-if="!party.active">
                            <i @click="deleteParty(party)" class="el-icon-delete menu-drawer-submenu__item"></i>    
                        </a>
                    </div>
                    <!-- <crm-settings
                        :name="$options.name"
                        :model="party"
                        :actions="actions"
                        :permissionShow="'deliveryParties.update'"
                        :permissionDestroy="'deliveryParties.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings> -->
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <!-- <el-drawer
               :with-header="false"  
               :visible.sync="drawerFilialProducts" 
               ref="drawerFilialProducts"
               size="90%"
               class="bg-se"
               @opened="drawerOpened('drawerFilialProductsChild')"
               @closed="drawerClosed('drawerFilialProductsChild')"
               >
                <div>
                    <filial-products ref="drawerFilialProductsChild" drawer="drawerFilialProducts"> </filial-products>
                </div>
            </el-drawer> -->
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="70%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create 
                        ref="drawerCreateChild" 
                        drawer="drawerCreate"> 
                    </crm-create>
                </div>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import filialProducts from "./components/incoming-filial-product-list";
import CrmCreate from "./components/create-party";
import SelectUsers from '@/components/inventory/select-users';

import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";

export default {
    name: "DeliverParties",
    mixins: [list],
    components: {
        filialProducts,
        CrmCreate,
        SelectUsers
    },

    data() {
        return {
            drawerFilialProducts: false,
            active_statuses: [
                {
                    value: 1,
                    label: this.$t('message.active')
                }, 
                {
                    value: 0,
                    label: this.$t('message.inactive')
                },
            ]
        };
    },

    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            list: "deliveryParties/list",
            columns: "deliveryParties/columns",
            pagination: "deliveryParties/pagination",            
            filter: "deliveryParties/filter",
            sort: "deliveryParties/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "deliveryParties/index",
            setPagination: "deliveryParties/setPagination",
            updateSort: "deliveryParties/updateSort",
            updateFilter: "deliveryParties/updateFilter",
            updateColumn: "deliveryParties/updateColumn",
            updatePagination: "deliveryParties/updatePagination",
            show: "deliveryParties/show",
            empty: "deliveryParties/empty",
            delete: "deliveryParties/destroy",
            refreshData: "deliveryParties/refreshData",
            save: "deliveryParties/store",
        }),
        createParty(){
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.save()
                    .then((res) => {
                        this.$alert(res);
                        this.fetchData();
                    }).catch((err) => {
                        this.$alert(err);
                    });
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        },

        deleteParty(party){
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                if(!party.active){
                    this.destroy(party.id);
                }else{
                    this.$notify({
                        title: this.$t('message.error'),
                        type: "error",
                        offset: 130,
                        message: this.$t('message.cannot_delete_active_party')
                    });
                }
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        }
    },

    beforeRouteLeave (to, from, next) {
        this.$store.commit('cdek/EMPTY_LIST');
        next()
    },
};
</script>

